import React,{useState} from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';

import asyncComponent from './asyncComponent';
import PrivateRoute from './PrivateRoute';
import '../App.css';
// import { Wave } from 'react-animated-text';

// PUBLIC ROUTES
const LandingPage = asyncComponent(() => import('../components/Landing'));
const LivestreamingPage = asyncComponent(() => import('../components/Livestreaming'));
const PremiumMembership = asyncComponent(() => import('../components/PremiumMembership'));
const AddMedia = asyncComponent(() => import('../components/admin/Media/AddMedia'));
const AllMedia = asyncComponent(() => import('../components/admin/Media/AllMedia'));
const SubscribePopup = asyncComponent(() =>
import('../components/SubscribePopup'),
);
const ChemicalSectorPage = asyncComponent(() =>
  import('../components/ChemicalSector'),
);
const TermsAndConditions = asyncComponent(() =>
  import('../components/TermsAndConditions'),
);
const PrivacyPolicy = asyncComponent(() =>
  import('../components/PrivacyPolicy'),
);
const RefundCancellationPolicy = asyncComponent(() =>
  import('../components/RefundCancellationPolicy'),
);
const BankingSectorPage = asyncComponent(() =>
  import('../components/BankingSector'),
);
const CoursePage = asyncComponent(() => import('../components/Course'));
const Blogs = asyncComponent(() => import('../components/Blogs'));
const Podcast = asyncComponent(() => import('../components/Podcast'));
const DedicatedPodcast = asyncComponent(() =>
  import('../components/DedicatedPodcast'),
);
const ConnectWithUs = asyncComponent(() =>
  import('../components/ConnectWithUs'),
);
const SignupThanks = asyncComponent(() =>
  import('../components/SignupThanksPage'),
);
const ThankYouPage = asyncComponent(() => import('../components/ThankYouPage'));
const EnquiryThankYouPage = asyncComponent(() =>
  import('../components/EnquiryThankYou'),
);
const Syngene = asyncComponent(() => import('../components/Syngene'));
const AdminLogin = asyncComponent(() => import('../components/admin/Login'));
const LumpSumCalculator = asyncComponent(() =>
  import('../components/LumpSumCalculator'),
);
const Visualization = asyncComponent(() =>
  import('../components/Visualization'),
);
const BlogDescription = asyncComponent(() =>
  import('../components/BlogDescription'),
);
const BlogCategory = asyncComponent(() => import('../components/BlogCategory'));
const SingleBook = asyncComponent(() => import('../components/SingleBook'));
const Books = asyncComponent(() => import('../components/Books'));

// PRIVATE ROUTES
const AdminDashboard = asyncComponent(() =>
  import('../components/admin/AdminDashboard'),
);
const ViewBlogsPage = asyncComponent(() =>
  import('../components/admin/Blogs/ViewBlogsPage'),
);
const AddBlogPage = asyncComponent(() =>
  import('../components/admin/Blogs/AddBlogPage'),
);
const ResetPasswordPage = asyncComponent(() =>
  import('../components/admin/ResetPassword'),
);
const ViewEnquiriesPage = asyncComponent(() =>
  import('../components/admin/Enquiries/ViewEnquiriesPage'),
);
const DownloadEnquiriesCSVPage = asyncComponent(() =>
  import('../components/admin/Enquiries/DownloadEnquiriesCSV'),
);
const EditBlogPage = asyncComponent(() =>
  import('../components/admin/Blogs/EditBlogPage'),
);
const ViewPodcastsPage = asyncComponent(() =>
  import('../components/admin/Podcasts/ViewPodcasts'),
);
const AddPodcastPage = asyncComponent(() =>
  import('../components/admin/Podcasts/AddPodcast'),
);
const ViewBooksPage = asyncComponent(() =>
  import('../components/admin/Books/ViewBooks'),
);
const AddBookPage = asyncComponent(() =>
  import('../components/admin/Books/AddBook'),
);
const ViewBlogsComments = asyncComponent(() =>
  import('../components/admin/Comments/BlogsComments/ViewBlogsComments'),
);
const ViewBlogComments = asyncComponent(() =>
  import('../components/admin/Comments/BlogsComments/ViewBlogComments'),
);
const ViewPodcastComments = asyncComponent(() =>
  import('../components/admin/Comments/PodcastComments/ViewPodcastComments'),
);
const ViewBooksComments = asyncComponent(() =>
  import('../components/admin/Comments/BooksComments/ViewBooksComments'),
);
const ViewBookComments = asyncComponent(() =>
  import('../components/admin/Comments/BooksComments/ViewBookComments'),
);
const AddVisualization = asyncComponent(() =>
  import('../components/admin/Visualization/AddVisualization'),
);
const ViewVisualization = asyncComponent(() =>
  import('../components/admin/Visualization/ViewVisualization'),
);
const EditVisualization = asyncComponent(() =>
  import('../components/admin/Visualization/EditVisualization'),
);
const AddYoutubeCollection = asyncComponent(() =>
  import('../components/admin/YoutubeCollections/AddYoutubeCollection'),
);
const ViewYoutubeCollection = asyncComponent(() =>
  import('../components/admin/YoutubeCollections/ViewYoutubeCollection'),
);
const EditYoutubeCollection = asyncComponent(() =>
  import('../components/admin/YoutubeCollections/EditYoutubeCollection'),
);
const AddEvent = asyncComponent(() =>
  import('../components/admin/Livestream/AddEvent'),
);
const ViewEvent = asyncComponent(() =>
  import('../components/admin/Livestream/ViewEvent'),
);
const EditEvent = asyncComponent(() =>
  import('../components/admin/Livestream/EditEvent'),
);
const Comments = asyncComponent(() => import('../components/admin/Comments'));
const EditBookPage = asyncComponent(() =>
  import('../components/admin/Books/EditBook'),
);
const EditPodcastPage = asyncComponent(() =>
  import('../components/admin/Podcasts/EditPodcast'),
);
const PayOne = asyncComponent(() =>
  import('../components/PaymentChemicals.js'),
);
const PayTwo = asyncComponent(() =>
  import('../components/PaymentBankingSpace.js'),
);
const PayThree = asyncComponent(() => 
  import('../components/PaymentModernMonopolies.js'),
)
const PageMetaData = asyncComponent(() =>
  import('../components/admin/Seo/PageMetaData'),
);
const BlogsListingForSeo = asyncComponent(() =>
  import('../components/admin/Seo/AllBlogsListing'),
);
const BooksListingForSeo = asyncComponent(() =>
  import('../components/admin/Seo/AllBooksListing'),
);
const PodcastListingForSeo = asyncComponent(() =>
  import('../components/admin/Seo/AllPodcastListing.js'),
);
const Members = asyncComponent(() =>
  import('../components/admin/Members/viewMembers'),
);
const AddMember = asyncComponent(() =>
  import('../components/admin/Members/addMember'),
);
const AutomationHistory = asyncComponent(() =>
import('../components/admin/Members/AutomationHistory'),
);
const EditMember = asyncComponent(() =>
  import('../components/admin/Members/editMember'),
);
const NotFoundPage = asyncComponent(() => import('../components/NotFoundPage'));
const RedirectToExternalPage = asyncComponent(() => import("../components/RedirectToExternalPage"));
const Subscribers = asyncComponent(() =>
  import('../components/admin/Subscribers'),
);



const YoutubeVideosFront = asyncComponent(() =>
  import('../components/YoutubeVideos'),
);



const AddAuthor = asyncComponent(() =>
  import("../components/admin/Authors/addAuthor")
);
const Authors = asyncComponent(() =>
  import("../components/admin/Authors/viewAuthors")
);
const EditAuthor = asyncComponent(() =>
  import("../components/admin/Authors/editAuthor")
);

const AuthorProfile = asyncComponent(() =>
  import("../components/authorProfile")
);

const AuthorReply = asyncComponent(() =>
  import("../components/authorReply")
);

const LazyRoutes = () => {
  const [delay15 ,setDelay15] = useState(false)
  // const [showHeader,setShowHeader] = useState(localStorage.getItem('loader') ? localStorage.getItem('loader') : false );
  // const openForm = () => {
  //   document.getElementById("popupForm").style.display = "block";
  // }
  // const closeForm = () => {
  //   document.getElementById("popupForm").style.display = "none"; 
  // }
 setTimeout(()=>{
   if (document.body.className != 'modal-opened' && localStorage.getItem('popupClosed') !== 1) {
      document.body.className += 'modal-opened';
   }

 
  setDelay15(true)
},5000)

  // const newpopup = () => {
  //   document.getElementById("popupForm").style.display = "none";
  //   document.getElementById('newPopup').style.visibility = "visible";
  //   document.getElementById("openform").style.display = "none";
  // }

  // const closepopup = () => {
  //   document.getElementById("newPopup").style.display = "none"; 
  // }
  // useEffect(() => {
  //   openForm();
  // }, []);
  return (
    <>
      {/* <div className="openBtn mt-5">
        <button className="openButton" id="openform" onClick={() => openForm()}><strong>Open Form</strong></button>
      </div> */}
      {/* <div className="loginPopup">
        <div className="formPopup" id="popupForm">
          <form action className="formContainer">
            <div className="close-button" onClick={() => closeForm()}><i className="fa fa-times" /></div>
            <img src="https://a.omappapi.com/users/f51c2110a83d/images/8b01ed1a71001620294320-SCHOOL-OF-INTRINSIC-COMPOUNDING.png" alt="" />
            <div className="written-content">
              <h2 className="main-heading"> <span>JOIN</span> THE <span>SOIC</span> COMMUNITY</h2>
              <p className="main-content">More than <span>20,000 +</span> people are a part of the tribe. Sign up now to receive <br /> Newsletters, <span>Videos</span>, <span>Blogs</span>,<span>Company <br /> Presentations/
                  Visualisations</span> exclusively <br /> for <span>FREE</span> </p>
            </div> */}
           {delay15 && <SubscribePopup page={'Subscription Popup'}/> }
          {/* </form>
        </div>
      </div>
      <div className="newpop" id="newPopup">
        <div className="forflex">
          <div className="close-button" id="Close" onClick={() => closepopup()}><i className="fa fa-times" /></div>
          <div className="congratulation"> <h2>SUCCESS! </h2> </div>
          <div className="content-12"> <p>Congratulations on becoming a <br />
              <span> SOIC Tribe Member</span> 🎉 You will receive all the latest updates!</p>
          </div>
          <div className="popimg">
            <img src="logo.webp" alt="" />
          </div>
        </div>
      </div> */}
    <Switch>
      {/* {window.screen.width >= 480 ?  */}
      <Route exact path="/" component={LandingPage} /> 
      {/* : 
      <Route exact path="/" component={MobileHome} /> 
      } */}
      {/* <Route exact path="/pay-chemicals" component={PayOne} />
      <Route exact path="/pay-banking" component={PayTwo} />
      <Route exact path="/pay-modern-monopolies" component={PayThree} /> */}

      {/* <Route exact path="/chemical-sector" component={ChemicalSectorPage} />
      <Route exact path="/banking-sector" component={BankingSectorPage} /> */}
      <Route
        exact
        path="/terms-and-conditions"
        component={TermsAndConditions}
      />
      <Route exact path="/privacy-policy" component={PrivacyPolicy} />
      <Route
        exact
        path="/refund-and-cancellation-policy"
        component={RefundCancellationPolicy}
      />

      <Route exact path="/course" component={CoursePage} />
      <Route exact path="/blogs" component={Blogs} />
      <Route exact path="/podcast" component={Podcast} />
      <Route exact path="/dedicated-podcast/:id" component={DedicatedPodcast} />
      <Route exact path="/connect-with-us" component={ConnectWithUs} />
      <Route exact path="/signup-thank-you" component={SignupThanks} />
      <Route exact path="/premium-membership" component={PremiumMembership} />
      {/* <Route exact path="/thank-you/:type" component={ThankYouPage} /> */}
      <Route exact path="/enquiry/thank-you" component={EnquiryThankYouPage} />
      <Route exact path="/syngene.html" component={Syngene} />
      <Route exact path="/admin/login" component={AdminLogin} />
      <Route exact path="/lumpsum-calculator" component={LumpSumCalculator} />
      <Route exact path="/visualization" component={Visualization} />
      <Route exact path="/soicevents" component={LivestreamingPage} />
      <Route exact path="/blog-description/:id" component={BlogDescription} />
      <Route exact path="/blogs/category/:cat" component={BlogCategory} />
      <Route exact path="/single-book/:id" component={SingleBook} />
      {/* <Route exact path="/books" component={Books} /> */}

      <Route exact path="/author-profile/:id" component={AuthorProfile} />


      <Route exact path="/tijorifinance" component={RedirectToExternalPage} />
      <Route exact path="/books" component={RedirectToExternalPage} />
      <Route exact path="/tradingview" component={RedirectToExternalPage} />
      <Route exact path="/stockedgeclub" component={RedirectToExternalPage} />
      <Route exact path="/stockedgepremium" component={RedirectToExternalPage} />

      <Route exact path="/author-reply/:author_id/:blog_id/:comment_id" component={AuthorReply} />
      <PrivateRoute
        exact
        path="/seo/all-blogs"
        component={BlogsListingForSeo}
      />
      <PrivateRoute
        exact
        path="/seo/all-books"
        component={BooksListingForSeo}
      />
      <PrivateRoute
        exact
        path="/seo/all-podcasts"
        component={PodcastListingForSeo}
      />
      <PrivateRoute exact path="/seo/:id" component={PageMetaData} />

      <PrivateRoute exact path="/admin" component={AdminDashboard} />
      <PrivateRoute exact path="/admin/subscribers" component={Subscribers} />
      {/* <PrivateRoute exact path='/admin/members' component={Members} />
      <PrivateRoute exact path='/admin/add-member' component={AddMember} /> */}
      {/* <PrivateRoute
        exact
        path="/admin/members/:id"
        component={EditMember}
      /> */}
      <PrivateRoute exact path="/admin/blogs" component={ViewBlogsPage} />
      <PrivateRoute exact path="/admin/add-blog" component={AddBlogPage} />
      <PrivateRoute exact path="/admin/add-media" component={AddMedia} />
      <PrivateRoute exact path="/admin/all-media" component={AllMedia} />
      
      <PrivateRoute
        exact
        path="/admin/change-password"
        component={ResetPasswordPage}
      />
      <PrivateRoute
        exact
        path="/admin/enquiries"
        component={ViewEnquiriesPage}
      />
      <PrivateRoute
        exact
        path="/admin/enquiries/csv-download"
        component={DownloadEnquiriesCSVPage}
      />
      <PrivateRoute path="/admin/blog/:id" component={EditBlogPage} />
      <PrivateRoute exact path="/admin/podcasts" component={ViewPodcastsPage} />
      <PrivateRoute
        exact
        path="/admin/podcasts/add"
        component={AddPodcastPage}
      />
      <PrivateRoute exact path="/admin/books" component={ViewBooksPage} />
      <PrivateRoute exact path="/admin/books/add" component={AddBookPage} />
      <PrivateRoute
        exact
        path="/admin/comments/blogs"
        component={ViewBlogComments}
      />
      <PrivateRoute
        exact
        path="/admin/comments/podcast"
        component={ViewPodcastComments}
        />
      <PrivateRoute
        exact
        path="/admin/comments/blogs/:id"
        component={ViewBlogComments}
      />
      <PrivateRoute
        exact
        path="/admin/comments/books"
        component={ViewBookComments}
      />
      <PrivateRoute
        exact
        path="/admin/comments/books/:id"
        component={ViewBookComments}
      />

      <PrivateRoute
        exact
        path="/admin/add-visualization"
        component={AddVisualization}
      />
      <PrivateRoute
        exact
        path="/admin/visualizations"
        component={ViewVisualization}
      />
      <PrivateRoute exact path="/admin/Comments" component={Comments} />
      <PrivateRoute
        exact
        path="/admin/visualization/:id"
        component={EditVisualization}
      />
      <PrivateRoute
        exact
        path="/admin/add-youtube-collection"
        component={AddYoutubeCollection}
      />
      <PrivateRoute
        exact
        path="/admin/youtube-collections"
        component={ViewYoutubeCollection}
      />
      <PrivateRoute
        exact
        path="/admin/youtube-collection/:id"
        component={EditYoutubeCollection}
      />
      <PrivateRoute
        exact
        path="/admin/add-event"
        component={AddEvent}
      />
      <PrivateRoute
        exact
        path="/admin/events"
        component={ViewEvent}
      />
      <PrivateRoute
        exact
        path="/admin/edit-event/:id"
        component={EditEvent}
      />
      <PrivateRoute path="/admin/books/:id" component={EditBookPage} />
      <PrivateRoute path="/admin/podcasts/:id" component={EditPodcastPage} />
      <PrivateRoute exact path='/admin/members' component={Members} />
      <PrivateRoute exact path='/admin/add-member' component={AddMember} />
      <PrivateRoute exact path='/admin/automation-history' component={AutomationHistory} />
      
      <PrivateRoute
        exact
        path="/admin/members/:id"
        component={EditMember}
      />
      <PrivateRoute exact path="/admin/authors" component={Authors} />
        <PrivateRoute exact path="/admin/add-author" component={AddAuthor} />
        <PrivateRoute exact path="/admin/authors/:id" component={EditAuthor} />
      <Route exact path="/youtube-collections" component={YoutubeVideosFront} />

      <Redirect
        from="/interview.html"
        to="/blog-description/samit-vartak-interview"
      />
      <Redirect
        from="/bharat-rasayan.html"
        to="/blog-description/bharat-rasayan"
      />
      <Redirect
        from="/pi-industries.html"
        to="/blog-description/pi=industries"
      />
      <Redirect
        from="/soicmembership"
        to="/soicmembership/"
      />
       {/* <Redirect
        from="/tijorifinance"
        to="https://tijorifinance.com/account/subscription/?code=SOIC025"
      />
      <Redirect
        from="/books"
        to="https://www.amazon.in/shop/soic"
      />
      <Redirect
        from="/tradingview"
        to="https://in.tradingview.com/?aff_id=113488"
      />
      <Redirect
        from="/stockedgeclub"
        to="https://www.stockedge.com/Plans/stockedge-club-annual-mr/26?affcode=333689"
      />
       <Redirect
        from="/stockedgepremium"
        to="https://www.stockedge.com/Plans/stockedge-premium-annual-mr/25?affcode=333689"
      /> */}
      <Route component={NotFoundPage} />
    </Switch>
    </>
  );
};

export default LazyRoutes;
