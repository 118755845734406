import {
  FETCH_BLOG_COMMENTS_START,
  FETCH_BLOG_COMMENTS_FAIL,
  FETCH_BLOG_COMMENTS_SUCCESS,
  FETCH_BOOK_COMMENTS_START,
  FETCH_BOOK_COMMENTS_FAIL,
  FETCH_BOOK_COMMENTS_SUCCESS,
  DELETE_BLOG_COMMENT_SUCCESS,
  DELETE_BLOG_COMMENT_START,
  DELETE_BLOG_COMMENT_FAIL,
  DELETE_BOOK_COMMENT_START,
  DELETE_BOOK_COMMENT_FAIL,
  DELETE_BOOK_COMMENT_SUCCESS,
  REPLY_COMMENT_FAIL,
  REPLY_COMMENT_START,
  REPLY_COMMENT_SUCCESS,
} from '../actions/types';

const initialState = {
  blogComments: [],
  bookComments: [],
  loading: false,
  deleting: false,
  sending: false,
};

const reducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case FETCH_BLOG_COMMENTS_START:
      return {
        ...state,
        loading: true,
        blogComments: [],
      };
    case FETCH_BLOG_COMMENTS_FAIL:
      return {
        ...state,
        loading: false,
        blogComments: [],
      };
    case FETCH_BLOG_COMMENTS_SUCCESS:
      return {
        ...state,
        loading: false,
        blogComments: payload,
      };
    case FETCH_BOOK_COMMENTS_START:
      return {
        ...state,
        loading: true,
        bookComments: [],
      };
    case FETCH_BOOK_COMMENTS_FAIL:
      return {
        ...state,
        loading: false,
        bookComments: [],
      };
    case FETCH_BOOK_COMMENTS_SUCCESS:
      return {
        ...state,
        loading: false,
        bookComments: payload,
      };
    case DELETE_BLOG_COMMENT_START:
      return {
        ...state,
        deleting: true,
      };
    case DELETE_BLOG_COMMENT_FAIL:
      return {
        ...state,
        deleting: false,
      };
    case DELETE_BLOG_COMMENT_SUCCESS:
      return {
        ...state,
        deleting: false,
        blogComments: state.blogComments.filter(
          comment => comment._id !== payload,
        ),
      };
    case DELETE_BOOK_COMMENT_START:
      return {
        ...state,
        deleting: true,
      };
    case DELETE_BOOK_COMMENT_FAIL:
      return {
        ...state,
        deleting: false,
      };
    case DELETE_BOOK_COMMENT_SUCCESS:
      return {
        ...state,
        deleting: false,
        bookComments: state.bookComments.filter(
          comment => comment._id !== payload,
        ),
      };
    case REPLY_COMMENT_START:
      return {
        ...state,
        sending: true,
      };
    case REPLY_COMMENT_FAIL:
      return {
        ...state,
        sending: false,
      };
    case REPLY_COMMENT_SUCCESS:
      return {
        ...state,
        sending: false,
      };
    default:
      return {
        ...state,
      };
  }
};

export default reducer;
