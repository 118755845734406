import axios from 'axios';
import { setAlert } from './alert';
import {
  USER_LOADED,
  AUTH_ERROR,
  LOGIN_SUCCESS,
  LOGIN_FAIL,
  LOGOUT,
  PASSWORD_SUCCESS,
  PASSWORD_SUCCESS_ERROR,
  PASSWORD_RESET_START,
} from './types';
import setAuthToken from '../utils/setAuthToken';

// const axios = ax.create({
//   baseURL: '',
// });

// Load User
export const loadUser = () => async dispatch => {
  // console.log(localStorage.token);
  setAuthToken(localStorage.getItem('token'));
  try {
    // axios.defaults.headers.common['x-auth-token'] = localStorage.getItem(
    //   'token',
    // );
    const res = await axios.get('/v1/adminLogin');

    dispatch({
      type: USER_LOADED,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: AUTH_ERROR,
    });
  }
};

// Login User
export const adminLogin = (email, password) => async dispatch => {
  const config = {
    headers: {
      'Content-Type': 'application/json',
    },
  };

  const body = JSON.stringify({ email, password });

  try {
    const res = await axios.post('/v1/adminLogin', body, config);

    dispatch({
      type: LOGIN_SUCCESS,
      payload: res.data,
    });

    // dispatch(loadUser());
    return res.data;
  } catch (err) {
    console.log(err);
    const errors = err.response.data.errors;

    if (errors) {
      errors.forEach(error => dispatch(setAlert(error.msg, 'danger')));
      dispatch({
        type: LOGIN_FAIL,
        payload: {
          error: errors[0].msg,
        },
      });
    }
    dispatch({
      type: LOGIN_FAIL,
      payload: {
        error: 'Something Went Wrong.',
      },
    });
    return errors;
  }
};

// Login User
export const changePassword = formData => async dispatch => {
  const config = {
    headers: {
      'Content-Type': 'application/json',
    },
  };

  try {
    dispatch({ type: PASSWORD_RESET_START });
    const res = await axios.put('/v1/adminLogin', formData, config);

    dispatch({
      type: PASSWORD_SUCCESS,
      payload: res.data,
    });

    dispatch(setAlert('Password Changed Success', 'success', 1000));
    return res.data;
  } catch (err) {
    const errors = err.response.data.errors;

    if (errors) {
      errors.forEach(error => dispatch(setAlert(error.msg, 'danger')));
    }
    dispatch({
      type: PASSWORD_SUCCESS_ERROR,
      payload: errors[0].msg,
    });

    setTimeout(() => {
      dispatch({
        type: PASSWORD_SUCCESS_ERROR,
        payload: null,
      });
    }, 3000);
    return errors;
  }
};
export const reply = formData => async dispatch => {
  const config = {
    headers: {
      'Content-Type': 'application/json',
    },
  };

  try {
    const res = await axios.post('/v1/adminLogin/reply', formData, config);

    dispatch({
      type: PASSWORD_SUCCESS,
      payload: res.data,
    });

    return res.data;
  } catch (err) {
    const errors = err.response.data.errors;

    if (errors) {
      errors.forEach(error => dispatch(setAlert(error.msg, 'danger')));
    }
    dispatch({
      type: PASSWORD_SUCCESS_ERROR,
    });
    return errors;
  }
};
// Logout / Clear Profile
export const logout = () => dispatch => {
  dispatch({ type: LOGOUT });
};

export const getHeaderContent = () => async dispatch => {
  try {
    const res = await axios.get('/v1/header');
    return res.data;
  } catch (err) {
    const errors = err.response.data.errors;
    return errors;
  }
};

export const updateHeaderContent = formData => async dispatch => {
  const config = {
    headers: {
      'Content-Type': 'application/json',
      'Authorization':`Bearer ${localStorage.getItem('token')}`
    },
  };

  try {
    const res = await axios.post('/v1/header/update_sticky_header', formData, config);
    return res.data;
  } catch (err) {
    const errors = err.response.data.errors;
    return errors;
  }
};
