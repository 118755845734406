import { combineReducers } from 'redux';
import alert from './alert';
import auth from './auth';
import blog from './blog';
import podcast from './podcasts';
import book from './books';
import visualization from './visualization';
import enquiry from './enquiry';
import comments from './comments';
import seo from './seo';
import livestream from './livestream';

export default combineReducers({
  alert,
  auth,
  blog,
  podcast,
  book,
  visualization,
  enquiry,
  comments,
  seo,
  livestream
});
