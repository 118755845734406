import React, { Fragment } from 'react';
import spinner from './spinner1.png';

export default () => (
  <Fragment>
    <div className="loaderImg">
      <img
        src={spinner}
        style={{marginLeft:'auto',marginRight:'auto', height:'100%', width:'100%', objectFit:'cover', verticalAlign:'middle', display: 'block' }}
        alt='Loading...'
      />
    </div>
  </Fragment>
);
