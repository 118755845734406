import {
    GET_EVENT_LIST,
    GET_EVENT_LIST_ERROR,
    GET_EVENT,
    GET_EVENT_ERROR,
    UPDATE_EVENT,
    UPDATE_EVENT_ERROR,
    DELETE_EVENT,
    DELETE_EVENT_ERROR,
    UPLOAD_IMAGE_START,
    UPLOAD_IMAGE_FINISH,
  } from '../actions/types';
  
  const initialState = {
    event: null,
    events: [],
    loading: true,
    error: {},
    uploading: false,
    uploadSuccess: false,
    createLoading: false,
  };
  
  export default function (state = initialState, action) {
    const { type, payload } = action;
  
    switch (type) {
      case GET_EVENT_LIST:
        return {
          ...state,
          events: payload,
          loading: false,
          uploading: false,
        };
      case GET_EVENT:
      case UPDATE_EVENT:
        return {
          ...state,
          event: payload,
          loading: false,
          uploading: false,
          createLoading:true
        };
      case DELETE_EVENT:
        return {
          ...state,
          events: state.events.filter((b) => b._id !== payload),
          loading: false,
          uploading: false,
        };
      case GET_EVENT_ERROR:
      case GET_EVENT_LIST_ERROR:
      case UPDATE_EVENT_ERROR:
      case DELETE_EVENT_ERROR:
        return {
          ...state,
          error: payload,
          loading: false,
          event: null,
          events: [],
          uploading: false,
        };
      case UPLOAD_IMAGE_START:
        return {
          ...state,
          uploading: true,
          uploadSuccess: false,
        };
      case UPLOAD_IMAGE_FINISH:
        return {
          ...state,
          uploading: false,
          uploadSuccess: true,
        };
      default:
        return state;
    }
  }
  