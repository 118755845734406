export const SET_ALERT = 'SET_ALERT';
export const REMOVE_ALERT = 'REMOVE_ALERT';
export const USER_LOADED = 'USER_LOADED';
export const AUTH_ERROR = 'AUTH_ERROR';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAIL = 'LOGIN_FAIL';
export const LOGOUT = 'LOGOUT';
export const CREATE_UNIVERSITY = 'CREATE_UNIVERSITY';
export const CREATE_UNIVERSITY_ERROR = 'CREATE_UNIVERSITY_ERROR';
export const GET_UNIVERSITY_LIST = 'GET_UNIVERSITY_LIST';
export const GET_UNIVERSITY_LIST_ERROR = 'GET_UNIVERSITY_LIST_ERROR';
export const GET_UNIVERSITY = 'GET_UNIVERSITY';
export const GET_UNIVERSITY_ERROR = 'GET_UNIVERSITY_ERROR';
export const UPDATE_UNIVERSITY = 'UPDATE_UNIVERSITY';
export const UPDATE_UNIVERSITY_ERROR = 'UPDATE_UNIVERSITY_ERROR';
export const DELETE_UNIVERSITY = 'DELETE_UNIVERSITY';
export const DELETE_UNIVERSITY_ERROR = 'DELETE_UNIVERSITY_ERROR';

export const ADD_COURSE = 'ADD_COURSE';
export const ADD_COURSE_ERROR = 'ADD_COURSE_ERROR';
export const GET_COURSE_LIST = 'GET_UNIVERSITY_LIST';
export const GET_COURSE_LIST_ERROR = 'GET_UNIVERSITY_LIST_ERROR';
export const GET_COURSE = 'GET_COURSE';
export const GET_COURSE_ERROR = 'GET_COURSE_ERROR';
export const UPDATE_COURSE = 'UPDATE_COURSE';
export const UPDATE_COURSE_ERROR = 'UPDATE_COURSE_ERROR';
export const DELETE_COURSE = 'DELETE_COURSE';
export const DELETE_COURSE_ERROR = 'DELETE_COURSE_ERROR';
export const UPDATE_HOME_BANNER_DETAILS = 'UPDATE_HOME_BANNER_DETAILS';
export const UPDATE_HOME_BANNER_DETAILS_ERROR =
  'UPDATE_HOME_BANNER_DETAILS_ERROR';
export const GET_HOME_BANNER_DETAILS = 'GET_HOME_BANNER_DETAILS';
export const GET_HOME_BANNER_DETAILS_ERROR = 'GET_HOME_BANNER_DETAILS_ERROR';
export const UPDATE_HOME_OUR_KEYS = 'UPDATE_HOME_OUR_KEYS';
export const UPDATE_HOME_OUR_KEYS_ERROR = 'UPDATE_HOME_OUR_KEYS_ERROR';
export const UPDATE_EXISTENCE_DETAILS_ERROR = 'UPDATE_EXISTENCE_DETAILS_ERROR';
export const UPDATE_EXISTENCE_DETAILS = 'UPDATE_EXISTENCE_DETAILS';
export const UPDATE_ADVANTAGES_DETAILS = 'UPDATE_ADVANTAGES_DETAILS';
export const UPDATE_ADVANTAGES_DETAILS_ERROR =
  'UPDATE_ADVANTAGES_DETAILS_ERROR';
export const UPDATE_HOME_SUPPORT = 'UPDATE_HOME_SUPPORT';
export const UPDATE_HOME_SUPPORT_ERROR = 'UPDATE_HOME_SUPPORT_ERROR';
export const UPDATE_CAREER_GUIDANCE = 'UPDATE_CAREER_GUIDANCE';
export const UPDATE_CAREER_GUIDANCE_ERROR = 'UPDATE_CAREER_GUIDANCE_ERROR';
export const UPDATE_OUR_APPROCH = 'UPDATE_OUR_APPROCH';
export const UPDATE_OUR_APPROCH_ERROR = 'UPDATE_OUR_APPROCH_ERROR';
export const UPDATE_APPROCH_QUESTIONS = 'UPDATE_APPROCH_QUESTIONS';
export const UPDATE_APPROCH_QUESTIONS_ERROR = 'UPDATE_APPROCH_QUESTIONS_ERROR';
export const UPDATE_FREE_COUNSELLING = 'UPDATE_FREE_COUNSELLING';
export const UPDATE_FREE_COUNSELLING_ERROR = 'UPDATE_FREE_COUNSELLING_ERROR';
export const UPDATE_BLOGS = 'UPDATE_BLOGS';
export const UPDATE_BLOGS_ERROR = 'UPDATE_BLOGS_ERROR';
export const GET_DISTANCE_LEARNING_DETAILS = 'GET_DISTANCE_LEARNING_DETAILS';
export const GET_DISTANCE_LEARNING_DETAILS_ERROR =
  'GET_DISTANCE_LEARNING_DETAILS_ERROR';
export const UPDATE_BANNER_DETAILS = 'UPDATE_BANNER_DETAILS';
export const UPDATE_BANNER_DETAILS_ERROR = 'UPDATE_BANNER_DETAILS_ERROR';
export const UPDATE_CAREER_VALUES = 'UPDATE_CAREER_VALUES';
export const UPDATE_CAREER_VALUES_ERRORS = 'UPDATE_CAREER_VALUES_ERRORS';
export const UPDATE_BENEFITS = 'UPDATE_BENEFITS';
export const UPDATE_BENEFITS_ERROR = 'UPDATE_BENEFITS_ERROR';
export const UPDATE_DIFFERENCE = 'UPDATE_DIFFERENCE';
export const UPDATE_DIFFERENCE_ERROR = 'UPDATE_DIFFERENCE_ERROR';
export const UPDATE_QUESTIONS = 'UPDATE_QUESTIONS';
export const UPDATE_QUESTIONS_ERROR = 'UPDATE_QUESTIONS_ERROR';
export const UPDATE_SECTION = 'UPDATE_SECTION';
export const UPDATE_SECTION_ERROR = 'UPDATE_SECTION_ERROR';

export const UPDATE_FOOTER = 'UPDATE_FOOTER';
export const UPDATE_FOOTER_ERROR = 'UPDATE_FOOTER_ERROR';
export const GET_FOOTER_DETAILS = 'GET_FOOTER_DETAILS';
export const GET_FOOTER_DETAILS_ERROR = 'GET_FOOTER_DETAILS_ERROR';

export const ADD_ENQUIRY = 'ADD_ENQUIRY';
export const ADD_ENQUIRY_ERROR = 'ADD_ENQUIRY_ERROR';
export const GET_ENQUIRY_LIST = 'GET_ENQUIRY_LIST';
export const GET_ENQUIRY_LIST_ERROR = 'GET_ENQUIRY_LIST_ERROR';
export const GET_ENQUIRY = 'GET_ENQUIRY';
export const GET_ENQUIRY_ERROR = 'GET_ENQUIRY_ERROR';
export const DELETE_ENQUIRY_ERROR = 'DELETE_ENQUIRY_ERROR';
export const DELETE_ENQUIRY = 'DELETE_ENQUIRY';
export const ADD_CONTACT_US = 'ADD_CONTACT_US';
export const ADD_CONTACT_US_ERROR = 'ADD_CONTACT_US_ERROR';
export const GET_CONTACT_US_LIST = 'GET_CONTACT_US_LIST';
export const GET_CONTACT_US_LIST_ERROR = 'GET_CONTACT_US_LIST_ERROR';
export const GET_CONTACT_US = 'GET_CONTACT_US';
export const GET_CONTACT_US_ERROR = 'GET_CONTACT_US_ERROR';
export const DELETE_CONTACT_US_ERROR = 'DELETE_CONTACT_US_ERROR';
export const DELETE_CONTACT_US = 'DELETE_CONTACT_US';
export const GET_ENQUIRY_PAGE = 'GET_ENQUIRY_PAGE';
export const GET_ENQUIRY_PAGE_ERROR = 'GET_ENQUIRY_PAGE_ERROR';
export const GET_CONTACT_US_PAGE = 'GET_CONTACT_US_PAGE';
export const GET_CONTACT_US_PAGE_ERROR = 'GET_CONTACT_US_PAGE_ERROR';
export const UPDATE_VISSION = 'UPDATE_VISSION';
export const UPDATE_VISSION_ERROR = 'UPDATE_VISSION_ERROR';
export const UPDATE_MISSION = 'UPDATE_MISSION';
export const UPDATE_MISSION_ERROR = 'UPDATE_MISSION_ERROR';
export const UPDATE_ADVANTAGES = 'UPDATE_ADVANTAGES';
export const UPDATE_ADVATAGES_ERROR = 'UPDATE_ADVATAGES_ERROR';
export const GET_COLLEGE_VIDYA_DETAILS = 'GET_COLLEGE_VIDYA_DETAILS';
export const GET_COLLEGE_VIDYA_DETAILS_ERROR =
  'GET_COLLEGE_VIDYA_DETAILS_ERROR';
export const PASSWORD_RESET_START = 'PASSWORD_RESET_START';
export const PASSWORD_SUCCESS = 'PASSWORD_SUCCESS';
export const PASSWORD_SUCCESS_ERROR = 'PASSWORD_SUCCESS_ERROR';
export const MY_PROFILE = 'https://soicdocs.s3-ap-south-1.amazonaws.com/';
export const API_PROFILE = '//soic.in/v1/pay/';
export const SITE_URL = '//soic.in';
// export const MY_PROFILE = '//18.222.159.1:5000/public/';
// export const API_PROFILE = '//18.222.159.1:5000/v1/pay/';
// export const SITE_URL = '//18.222.159.1:5000';
// export const MY_PROFILE = '//localhost:5000/public/';
export const GET_BLOG = 'GET_BLOG';
export const GET_BLOG_ERROR = 'GET_BLOG_ERROR';
export const UPDATE_BLOG = 'UPDATE_BLOG';
export const UPDATE_BLOG_START = 'UPDATE_BLOG_START';
export const UPDATE_BLOG_ERROR = 'UPDATE_BLOG_ERROR';
export const DELETE_BLOG = 'DELETE_BLOG';
export const DELETE_BLOG_ERROR = 'DELETE_BLOG_ERROR';
export const GET_BLOG_LIST = 'GET_BLOG_LIST';
export const GET_BLOG_LIST_ERROR = 'GET_BLOG_LIST_ERROR';
export const RESET_BLOG_LOADING = 'RESET_BLOG_LOADING';

export const UPLOAD_IMAGE_START = 'UPLOAD_IMAGE_START';
export const UPLOAD_IMAGE_FINISH = 'UPLOAD_IMAGE_FINISH';
export const UPLOAD_IMAGE_FAIL = 'UPLOAD_IMAGE_FAIL';

export const GET_FAQ_LIST = 'GET_FAQ_LIST';
export const GET_FAQ_LIST_ERROR = 'GET_FAQ_LIST_ERROR';
export const GET_FAQ = 'GET_FAQ';
export const GET_FAQ_ERROR = 'GET_FAQ_ERROR';
export const UPDATE_FAQ = 'UPDATE_FAQ';
export const UPDATE_FAQ_ERROR = 'UPDATE_FAQ_ERROR';
export const DELETE_FAQ = 'DELETE_FAQ';
export const DELETE_FAQ_ERROR = 'DELETE_FAQ_ERROR';

export const UPDATE_COMPARE_UNIVERSITY_BANNER_DETAILS =
  'UPDATE_COMPARE_UNIVERSITY_BANNER_DETAILS';
export const UPDATE_COMPARE_UNIVERSITY_BANNER_DETAILS_ERROR =
  'UPDATE_COMPARE_UNIVERSITY_BANNER_DETAILS_ERROR';
export const GET_COMPARE_UNIVERSITY_BANNER_DETAILS =
  'GET_COMPARE_UNIVERSITY_BANNER_DETAILS';
export const GET_COMPARE_UNIVERSITY_BANNER_DETAILS_ERROR =
  'GET_COMPARE_UNIVERSITY_BANNER_DETAILS_ERROR';

export const GET_NOTICE_LIST = 'GET_NOTICE_LIST';
export const GET_NOTICE_LIST_ERROR = 'GET_NOTICE_LIST_ERROR';
export const GET_NOTICE = 'GET_NOTICE';
export const GET_NOTICE_ERROR = 'GET_NOTICE_ERROR';
export const UPDATE_NOTICE = 'UPDATE_NOTICE';
export const UPDATE_NOTICE_ERROR = 'UPDATE_NOTICE_ERROR';
export const DELETE_NOTICE = 'DELETE_NOTICE';
export const DELETE_NOTICE_ERROR = 'DELETE_NOTICE_ERROR';
export const SITE_KEY = '6LfwE8cZAAAAACEFUfjT889W-eZhf9-KM1HAAP3t';
export const SECRET_KEY = '6LfwE8cZAAAAAGCNZGeMdguzLGAfctoJHMxR5Iuc';
export const DELETE_VISUALIZATION = 'DELETE_VISUALIZATION';
export const DELETE_VISUALIZATION_ERROR = 'DELETE_VISUALIZATION_ERROR';

export const GET_VISUALIZATION_LIST = 'GET_VISUALIZATION_LIST';
export const GET_VISUALIZATION_LIST_ERROR = 'GET_VISUALIZATION_LIST_ERROR';
export const GET_VISUALIZATION = 'GET_VISUALIZATION';
export const GET_VISUALIZATION_ERROR = 'GET_VISUALIZATION_ERROR';
export const UPDATE_VISUALIZATION = 'UPDATE_VISUALIZATION';
export const UPDATE_VISUALIZATION_ERROR = 'UPDATE_VISUALIZATION_ERROR';
// export const GET_PODCAST = 'GET_PODCAST';
// export const GET_PODCAST_ERROR = 'GET_PODCAST_ERROR';
// export const GET_PODCAST_LIST = 'GET_PODCAST_LIST';
// export const GET_PODCAST_LIST_ERROR = 'GET_PODCAST_LIST_ERROR';

export const ADD_PODCAST_START = 'ADD_PODCAST_START';
export const ADD_PODCAST_SUCCESS = 'ADD_PODCAST_SUCCESS';
export const ADD_PODCAST_ERROR = 'ADD_PODCAST_ERROR';
export const FETCH_PODCASTS_START = 'FETCH_PODCASTS_START';
export const FETCH_PODCASTS_SUCCESS = 'FETCH_PODCASTS_SUCCESS';
export const FETCH_PODCASTS_ERROR = 'FETCH_PODCASTS_ERROR';
export const FETCH_PODCAST_START = 'FETCH_PODCAST_START';
export const FETCH_PODCAST_SUCCESS = 'FETCH_PODCAST_SUCCESS';
export const FETCH_PODCAST_ERROR = 'FETCH_PODCAST_ERROR';
export const UPDATE_PODCAST_START = 'UPDATE_PODCAST_START';
export const UPDATE_PODCAST_SUCCESS = 'UPDATE_PODCAST_SUCCESS';
export const UPDATE_PODCAST_ERROR = 'UPDATE_PODCAST_ERROR';
export const DELETE_PODCAST = 'DELETE_PODCAST';
export const DELETE_PODCAST_ERROR = 'DELETE_PODCAST_ERROR';
export const RESET_PODCAST_LOADING = 'RESET_PODCAST_LOADING';

export const UPDATE_BOOKS = 'UPDATE_BOOKS';
export const UPDATE_BOOKS_ERROR = 'UPDATE_BOOKS_ERROR';
export const DELETE_BOOKS = 'DELETE_BOOKS';
export const DELETE_BOOKS_ERROR = 'DELETE_BOOKS_ERROR';
export const GET_BOOKS_LIST = 'GET_BOOKS_LIST';
export const GET_BOOKS_LIST_ERROR = 'GET_BOOKS_LIST_ERROR';
export const GET_BOOK = 'GET_BOOK';
export const GET_BOOK_ERROR = 'GET_BOOK_ERROR';
export const UPDATE_BOOKS_START = 'UPDATE_PODCAST_START';
export const RESET_BOOKS_LOADING = 'RESET_PODCAST_LOADING';

// ACTION TYPES FOR COURSE
export const FETCH_COURSES_START = 'FETCH_COURSES_START';
export const FETCH_COURSES_SUCCESS = 'FETCH_COURSES_SUCCESS';
export const FETCH_COURSES_FAIL = 'FETCH_COURSES_FAIL';
export const FETCH_COURSE_START = 'FETCH_COURSE_START';
export const FETCH_COURSE_SUCCESS = 'FETCH_COURSE_SUCCESS';
export const FETCH_COURSE_FAIL = 'FETCH_COURSE_FAIL';
export const ADD_COURSE_START = 'ADD_COURSE_START';
export const ADD_COURSE_SUCCESS = 'ADD_COURSE_SUCCESS';
export const ADD_COURSE_FAIL = 'ADD_COURSE_FAIL';
export const UPDATE_COURSE_START = 'UPDATE_COURSE_START';
export const UPDATE_COURSE_SUCCESS = 'UPDATE_COURSE_SUCCESS';
export const UPDATE_COURSE_FAIL = 'UPDATE_COURSE_FAIL';
export const DELETE_COURSE_START = 'DELETE_COURSE_START';
export const DELETE_COURSE_SUCCESS = 'DELETE_COURSE_SUCCESS';
export const DELETE_COURSE_FAIL = 'DELETE_COURSE_FAIL';

// TYPES FOR ENQUIRY
export const ADD_ENQUIRY_START = 'ADD_ENQUIRY_START';
export const ADD_ENQUIRY_SUCCESS = 'ADD_ENQUIRY_SUCCESS';
export const ADD_ENQUIRY_FAIL = 'ADD_ENQUIRY_FAIL';
export const FETCH_ENQUIRIES_START = 'FETCH_ENQUIRIES_START';
export const FETCH_ENQUIRIES_SUCCESS = 'FETCH_ENQUIRIES_SUCCESS';
export const FETCH_ENQUIRIES_FAIL = 'FETCH_ENQUIRIES_FAIL';
export const FETCH_ENQUIRY_START = 'FETCH_ENQUIRY_START';
export const FETCH_ENQUIRY_SUCCESS = 'FETCH_ENQUIRY_SUCCESS';
export const FETCH_ENQUIRY_FAIL = 'FETCH_ENQUIRY_FAIL';
export const REPLY_ENQUIRY_START = 'REPLY_ENQUIRY_START';
export const REPLY_ENQUIRY_SUCCESS = 'REPLY_ENQUIRY_SUCCESS';
export const REPLY_ENQUIRY_FAIL = 'REPLY_ENQUIRY_FAIL';
export const FETCH_ENQUIRY_EXCEL_DATA_START = 'FETCH_ENQUIRY_EXCEL_DATA_START';
export const FETCH_ENQUIRY_EXCEL_DATA_SUCCESS =
  'FETCH_ENQUIRY_EXCEL_DATA_SUCCESS';
export const FETCH_ENQUIRY_EXCEL_DATA_FAIL = 'FETCH_ENQUIRY_EXCEL_DATA_FAIL';

export const GET_COMMENT_LIST = 'GET_COMMENT_LIST';
export const GET_COMMENT_LIST_ERROR = 'GET_COMMENT_LIST_ERROR';
export const DELETE_COMMENT = 'DELETE_COMMENT';
export const DELETE_COMMENT_ERROR = 'DELETE_COMMENT_ERROR';
export const ADD_COMMENT = 'ADD_COMMENT';
export const ADD_COMMENT_START = 'ADD_COMMENT_START';
export const ADD_COMMENT_ERROR = 'ADD_COMMENT_ERROR';
export const GET_COMMENT = 'GET_COMMENT';
export const GET_COMMENT_ERROR = 'GET_COMMENT_ERROR';
export const RESET_COMMENT_LOADING = 'RESET_COMMENT_LOADING';

export const GET_COMMENT_REPLY = 'GET_COMMENT_REPLY';
export const GET_COMMENT_REPLY_ERROR = 'GET_COMMENT_REPLY_ERROR';
export const ADD_COMMENT_REPLY = 'ADD_COMMENT_REPLY';
export const ADD_COMMENT_REPLY_ERROR = 'ADD_COMMENT_REPLY_ERROR';

// TYPES FOR COMMENTS
export const FETCH_BLOG_COMMENTS_START = 'FETCH_BLOG_COMMENTS_START';
export const FETCH_BLOG_COMMENTS_FAIL = 'FETCH_BLOG_COMMENTS_FAIL';
export const FETCH_BLOG_COMMENTS_SUCCESS = 'FETCH_BLOG_COMMENTS_SUCCESS';
export const FETCH_BOOK_COMMENTS_START = 'FETCH_BOOK_COMMENTS_START';
export const FETCH_BOOK_COMMENTS_FAIL = 'FETCH_BOOK_COMMENTS_FAIL';
export const FETCH_BOOK_COMMENTS_SUCCESS = 'FETCH_BOOK_COMMENTS_SUCCESS';
export const DELETE_BLOG_COMMENT_START = 'DELETE_BLOG_COMMENT_START';
export const DELETE_BLOG_COMMENT_FAIL = 'DELETE_BLOG_COMMENT_FAIL';
export const DELETE_BLOG_COMMENT_SUCCESS = 'DELETE_BLOG_COMMENT_SUCCESS';
export const DELETE_BOOK_COMMENT_START = 'DELETE_BOOK_COMMENT_START';
export const DELETE_BOOK_COMMENT_FAIL = 'DELETE_BOOK_COMMENT_FAIL';
export const DELETE_BOOK_COMMENT_SUCCESS = 'DELETE_BOOK_COMMENT_SUCCESS';
export const REPLY_COMMENT_START = 'REPLY_COMMENT_START';
export const REPLY_COMMENT_FAIL = 'REPLY_COMMENT_FAIL';
export const REPLY_COMMENT_SUCCESS = 'REPLY_COMMENT_SUCCESS';
//export const RAZORPAYID = 'rzp_test_GBjlxvPvZcothb';

export const RAZORPAYID = 'rzp_live_0vtEYyYSkH6EWL';
// SEO
export const SEO_UPDATE_START = 'SEO_UPDATE_START';
export const SEO_UPDATE_FAIL = 'SEO_UPDATE_FAIL';
export const SEO_UPDATE_SUCCESS = 'SEO_UPDATE_SUCCESS';
export const FETCH_SEO_START = 'FETCH_SEO_START';
export const FETCH_SEO_FAIL = 'FETCH_SEO_FAIL';
export const FETCH_SEO_SUCCESS = 'FETCH_SEO_SUCCESS';

// SUBSCRIBER
export const ADD_SUBSCRIBER_START = 'ADD_SUBSCRIBER_START';
export const ADD_SUBSCRIBER_DONE = 'ADD_SUBSCRIBER_DONE';
export const ADD_SUBSCRIBER_FAIL = 'ADD_SUBSCRIBER_FAIL';
export const RESET_STATUS = 'RESET_STATUS';
export const FETCH_SUBSCRIBERS_START = 'FETCH_SUBSCRIBERS_START';
export const FETCH_SUBSCRIBERS_DONE = 'FETCH_SUBSCRIBERS_DONE';
export const FETCH_SUBSCRIBERS_FAIL = 'FETCH_SUBSCRIBERS_FAIL';

export const GET_SEARCH_LIST = 'GET_SEARCH_LIST';
export const GET_SEARCH_LIST_ERROR = 'GET_SEARCH_LIST_ERROR';

export const GET_EVENT_LIST = 'GET_EVENT_LIST';
export const GET_EVENT_LIST_ERROR = 'GET_EVENT_LIST_ERROR';
export const GET_EVENT = 'GET_EVENT';
export const GET_EVENT_ERROR = 'GET_EVENT_ERROR';
export const UPDATE_EVENT = 'UPDATE_EVENT';
export const UPDATE_EVENT_ERROR = 'UPDATE_EVENT_ERROR';
export const DELETE_EVENT = 'DELETE_EVENT';
export const DELETE_EVENT_ERROR = 'DELETE_EVENT_ERROR';


// MEMBER
export const ADD_MEMBER_START = 'ADD_MEMBER_START';
export const ADD_MEMBER_DONE = 'ADD_MEMBER_DONE';
export const ADD_MEMBER_FAIL = 'ADD_MEMBER_FAIL';
export const RESET_STATUS_MEMBER = 'RESET_STATUS_MEMBER';
export const FETCH_MEMBER_START = 'FETCH_MEMBER_START';
export const FETCH_MEMBER_DONE = 'FETCH_MEMBER_DONE';
export const FETCH_MEMBER_FAIL = 'FETCH_MEMBER_FAIL';
export const DELETE_MEMBER = 'DELETE_MEMBER';
export const DELETE_MEMBER_ERROR = 'DELETE_MEMBER_ERROR';
export const ONE_MEMBER = 'ONE_MEMBER';
export const ONE_MEMBER_ERROR = 'ONE_MEMBER_ERROR';
export const UPDATE_MEMBER_START = 'UPDATE_MEMBER_START';
export const UPDATE_MEMBER = 'UPDATE_MEMBER';
export const UPDATE_MEMBER_ERROR = 'UPDATE_MEMBER_ERROR';
export const UPDATE_SEND_MAIL_START = "UPDATE_SEND_MAIL_START";
export const UPDATE_SEND_MAIL = "UPDATE_SEND_MAIL";
export const UPDATE_SEND_MAIL_ERROR = "UPDATE_SEND_MAIL_ERROR";

export const UPLOAD_MEDIA = "UPLOAD_MEDIA";
export const UPLOAD_MEDIA_ERROR = "UPLOAD_MEDIA_ERROR";
export const GET_ALL_MEDIA = "GET_ALL_MEDIA";
export const GET_ALL_MEDIA_ERROR = "GET_ALL_MEDIA_ERROR";