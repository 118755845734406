import {
  GET_BLOG,
  GET_BLOG_ERROR,
  UPDATE_BLOG,
  UPDATE_BLOG_ERROR,
  DELETE_BLOG,
  DELETE_BLOG_ERROR,
  GET_BLOG_LIST,
  GET_BLOG_LIST_ERROR,
  UPLOAD_IMAGE_START,
  UPLOAD_IMAGE_FINISH,
  UPDATE_BLOG_START,
  RESET_BLOG_LOADING,
} from '../actions/types';

const initialState = {
  blog: null,
  blogs: [],
  loading: true,
  error: {},
  uploading: false,
  uploadSuccess: false,
  createLoading: false,
};

export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case GET_BLOG_LIST:
      return {
        ...state,
        blogs: payload,
        loading: false,
        uploading: false,
      };
    case GET_BLOG:
      return {
        ...state,
        blog: payload,
        loading: false,
        uploading: false,
      };
    case UPDATE_BLOG_START:
      return {
        ...state,
        createLoading: true,
      };
    case UPDATE_BLOG:
      return {
        ...state,
        blog: payload,
        loading: false,
        uploading: false,
        createLoading: false,
      };
    case DELETE_BLOG:
      return {
        ...state,
        blogs: state.blogs.filter(b => b._id !== payload),
        loading: false,
        uploading: false,
        createLoading: false,
      };
    case GET_BLOG_ERROR:
    case GET_BLOG_LIST_ERROR:
    case DELETE_BLOG_ERROR:
    case UPDATE_BLOG_ERROR:
      return {
        ...state,
        error: payload,
        loading: false,
        blog: null,
        blogs: [],
        uploading: false,
      };
    case UPLOAD_IMAGE_START:
      return {
        ...state,
        uploading: true,
        uploadSuccess: false,
      };
    case UPLOAD_IMAGE_FINISH:
      return {
        ...state,
        uploading: false,
        uploadSuccess: true,
      };
    case RESET_BLOG_LOADING:
      return {
        ...state,
        createLoading: false,
      };
    default:
      return state;
  }
}
