import {
  FETCH_SEO_FAIL,
  FETCH_SEO_START,
  FETCH_SEO_SUCCESS,
  SEO_UPDATE_FAIL,
  SEO_UPDATE_START,
  SEO_UPDATE_SUCCESS,
} from '../actions/types';

const initialState = {
  updating: false,
  fetching: false,
};

function reducer(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case SEO_UPDATE_START:
      return {
        ...state,
        updating: true,
      };
    case SEO_UPDATE_FAIL:
      return {
        ...state,
        updating: false,
      };
    case SEO_UPDATE_SUCCESS:
      return {
        ...state,
        updating: false,
      };
    case FETCH_SEO_FAIL:
      return {
        ...state,
        fetching: false,
      };
    case FETCH_SEO_START:
      return {
        ...state,
        fetching: true,
      };
    case FETCH_SEO_SUCCESS:
      return {
        ...state,
        fetching: false,
      };
    default:
      return {
        ...state,
      };
  }
}

export default reducer;
