import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Spinner from '../components/Spinner'
const PrivateRoute = ({
  component: Component,
  auth: { isAuthenticated, loading },
  ...rest
}) => (
  <div>
 {loading ? <Spinner /> : 

(isAuthenticated ? <Route
  {...rest}
  render={props =>
    (
      <Component {...props} />
    )
  }
/> : <Redirect to='/admin/login' />
)}
  </div>
  
);

PrivateRoute.propTypes = {
  auth: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  auth: state.auth
});

export default connect(mapStateToProps)(PrivateRoute);
