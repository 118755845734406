import {
  USER_LOADED,
  AUTH_ERROR,
  LOGIN_SUCCESS,
  LOGIN_FAIL,
  LOGOUT,
  PASSWORD_RESET_START,
  PASSWORD_SUCCESS,
  PASSWORD_SUCCESS_ERROR,
} from '../actions/types';

const initialState = {
  token: localStorage.getItem('token'),
  UserRole: localStorage.getItem('UserRole'),
  isAuthenticated: false,
  loading: true,
  user: null,
  errorMessage: null,
  resetPasswordLoading: false,
};

export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case USER_LOADED:
      return {
        ...state,
        isAuthenticated: true,
        loading: false,
        user: payload,
        errorMessage: null,
      };
    case LOGIN_SUCCESS:
      localStorage.setItem('token', payload.data);
      localStorage.setItem('UserRole', 'ADMIN');
      return {
        ...state,
        // ...payload,
        isAuthenticated: true,
        UserRole: 'ADMIN',
        loading: false,
        errorMessage: null,
        token: payload.data,
      };
    case AUTH_ERROR:
      localStorage.removeItem('token');
      localStorage.removeItem('UserRole');
      return {
        ...state,
        token: null,
        isAuthenticated: false,
        loading: false,
        errorMessage: null,
      };
    case LOGIN_FAIL:
      localStorage.removeItem('token');
      localStorage.removeItem('UserRole');
      return {
        ...state,
        token: null,
        isAuthenticated: false,
        loading: false,
        errorMessage: payload.error || null,
      };
    case LOGOUT:
      localStorage.removeItem('token');
      localStorage.removeItem('UserRole');
      return {
        ...state,
        token: null,
        isAuthenticated: false,
        loading: false,
        errorMessage: null,
      };
    case PASSWORD_RESET_START:
      return {
        ...state,
        resetPasswordLoading: true,
        errorMessage: null,
      };
    case PASSWORD_SUCCESS:
      return {
        ...state,
        resetPasswordLoading: false,
        errorMessage: null,
      };
    case PASSWORD_SUCCESS_ERROR:
      return {
        ...state,
        resetPasswordLoading: false,
        errorMessage: payload || null,
      };
    default:
      return state;
  }
}
