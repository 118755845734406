import {
  ADD_ENQUIRY_START,
  ADD_ENQUIRY_FAIL,
  ADD_ENQUIRY_SUCCESS,
  FETCH_ENQUIRIES_START,
  FETCH_ENQUIRIES_FAIL,
  FETCH_ENQUIRIES_SUCCESS,
  REPLY_ENQUIRY_START,
  REPLY_ENQUIRY_FAIL,
  REPLY_ENQUIRY_SUCCESS,
  FETCH_ENQUIRY_START,
  FETCH_ENQUIRY_FAIL,
  FETCH_ENQUIRY_SUCCESS,
  FETCH_ENQUIRY_EXCEL_DATA_START,
  FETCH_ENQUIRY_EXCEL_DATA_FAIL,
  FETCH_ENQUIRY_EXCEL_DATA_SUCCESS,
  ADD_SUBSCRIBER_START,
  ADD_SUBSCRIBER_DONE,
  ADD_SUBSCRIBER_FAIL,
  FETCH_SUBSCRIBERS_FAIL,
  FETCH_SUBSCRIBERS_DONE,
  FETCH_SUBSCRIBERS_START,
  RESET_STATUS,
} from '../actions/types';

const initialState = {
  enquiries: [],
  enquiry: null,
  loading: false,
  fetchAllLoading: false,
  fetchOneLoading: false,
  sending: false,
  fetchExcelLoading: false,
  subscribeStatus: null,
  subscribers: null,
  loadingSub: false,
};

const reducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case ADD_ENQUIRY_START:
      return {
        ...state,
        loading: true,
      };
    case ADD_ENQUIRY_FAIL:
      return {
        ...state,
        loading: false,
      };
    case ADD_ENQUIRY_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case FETCH_ENQUIRIES_START:
      return {
        ...state,
        fetchAllLoading: true,
      };
    case FETCH_ENQUIRIES_FAIL:
      return {
        ...state,
        fetchAllLoading: false,
      };
    case FETCH_ENQUIRIES_SUCCESS:
      return {
        ...state,
        enquiries: payload,
        fetchAllLoading: false,
      };
    case FETCH_ENQUIRY_START:
      return {
        ...state,
        fetchOneLoading: true,
      };
    case FETCH_ENQUIRY_FAIL:
      return {
        ...state,
        fetchOneLoading: false,
      };
    case FETCH_ENQUIRY_SUCCESS:
      return {
        ...state,
        enquiry: payload,
        fetchOneLoading: false,
      };
    case REPLY_ENQUIRY_START:
      return {
        ...state,
        sending: true,
      };
    case REPLY_ENQUIRY_FAIL:
      return {
        ...state,
        sending: false,
      };
    case REPLY_ENQUIRY_SUCCESS:
      return {
        ...state,
        sending: false,
      };
    case FETCH_ENQUIRY_EXCEL_DATA_START:
      return {
        ...state,
        fetchExcelLoading: true,
      };
    case FETCH_ENQUIRY_EXCEL_DATA_FAIL:
      return {
        ...state,
        fetchExcelLoading: false,
      };
    case FETCH_ENQUIRY_EXCEL_DATA_SUCCESS:
      return {
        ...state,
        fetchExcelLoading: false,
      };
    case ADD_SUBSCRIBER_START:
      return {
        ...state,
      };
    case ADD_SUBSCRIBER_DONE:
      return {
        ...state,
        subscribeStatus: 201,
      };
    case ADD_SUBSCRIBER_FAIL:
      return {
        ...state,
        subscribeStatus: 403,
      };
    case RESET_STATUS:
      return {
        ...state,
        subscribeStatus: null,
      };
    case FETCH_SUBSCRIBERS_START:
      return {
        ...state,
        loadingSub: true,
      };
    case FETCH_SUBSCRIBERS_FAIL:
      return {
        ...state,
        loadingSub: false,
        subscribers: null,
      };
    case FETCH_SUBSCRIBERS_DONE:
      return {
        ...state,
        loadingSub: false,
        subscribers: payload,
      };
    default:
      return {
        ...state,
      };
  }
};

export default reducer;
