import React, { Fragment, useEffect,useState } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
// import Routes from './routing/Routes';

import { Provider } from 'react-redux';
import store from './store';
import { loadUser } from './actions/auth';
import setAuthToken from './utils/setAuthToken';
import LazyRoutes from './routing/LazyRoutes';
import { Helmet } from 'react-helmet';


if (localStorage.token) {
  setAuthToken(localStorage.token);
}

const App = () => {
  const [cookiePopuopClosed,setCookiePopupClosed] = useState(false);
  useEffect(() => {
    store.dispatch(loadUser());
    if(!localStorage.getItem('cookie_accepted')){
      var div = document.createElement('div');
      div.id = 'cookie';
   
      document.body.appendChild(div);
      var cookiePopup = '';
          cookiePopup += '<div class="card">';
          cookiePopup += '<?xml version="1.0" encoding="utf-8"?><svg  onclick="localStorage.setItem(`cookie_accepted`,1);window.location = window.location" class="cross-right" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="21.31px" height="22.876px" viewBox="0 0 121.31 122.876" enable-background="new 0 0 121.31 122.876" xml:space="preserve"><g><path fill-rule="evenodd" clip-rule="evenodd" d="M90.914,5.296c6.927-7.034,18.188-7.065,25.154-0.068 c6.961,6.995,6.991,18.369,0.068,25.397L85.743,61.452l30.425,30.855c6.866,6.978,6.773,18.28-0.208,25.247 c-6.983,6.964-18.21,6.946-25.074-0.031L60.669,86.881L30.395,117.58c-6.927,7.034-18.188,7.065-25.154,0.068 c-6.961-6.995-6.992-18.369-0.068-25.397l30.393-30.827L5.142,30.568c-6.867-6.978-6.773-18.28,0.208-25.247 c6.983-6.963,18.21-6.946,25.074,0.031l30.217,30.643L90.914,5.296L90.914,5.296z"/></g></svg>'
          cookiePopup += '<svg viewBox="0 0 512 512" width="100" title="cookie-bite" style="align-self: center;">';
          cookiePopup += '<path d="M510.52 255.82c-69.97-.85-126.47-57.69-126.47-127.86-70.17 0-127-56.49-127.86-126.45-27.26-4.14-55.13.3-79.72 12.82l-69.13 35.22a132.221 132.221 0 0 0-57.79 57.81l-35.1 68.88a132.645 132.645 0 0 0-12.82 80.95l12.08 76.27a132.521 132.521 0 0 0 37.16 72.96l54.77 54.76a132.036 132.036 0 0 0 72.71 37.06l76.71 12.15c27.51 4.36 55.7-.11 80.53-12.76l69.13-35.21a132.273 132.273 0 0 0 57.79-57.81l35.1-68.88c12.56-24.64 17.01-52.58 12.91-79.91zM176 368c-17.67 0-32-14.33-32-32s14.33-32 32-32 32 14.33 32 32-14.33 32-32 32zm32-160c-17.67 0-32-14.33-32-32s14.33-32 32-32 32 14.33 32 32-14.33 32-32 32zm160 128c-17.67 0-32-14.33-32-32s14.33-32 32-32 32 14.33 32 32-14.33 32-32 32z" />';
          cookiePopup += '</svg>';
          cookiePopup += '<h3>Cookies Consent</h3>';
          cookiePopup += '<p>We use cookies on this website, to ensure optimal experience whilst viewing our site.</p>';
          cookiePopup += '<div class="buttons__cta"  onclick="localStorage.setItem(`cookie_accepted`,1);window.location = window.location">';
          cookiePopup += '<button type="button" >Accept</button>';
          cookiePopup += '<button type="button" class="button1">Decline</button>';
          cookiePopup += '</div>';
          cookiePopup += '</div>';
          document.getElementById('cookie').innerHTML = cookiePopup;
    }

  }, []);
  return (
    <Provider store={store}>
      {localStorage.getItem('cookie_accepted') && 
      <Router>
        <Fragment>
          <div id="wrapper">
            <Helmet>
            <script async src="https://www.googletagmanager.com/gtag/js?id=UA-172766018-1"></script>
            <script>
                {`window.dataLayer = window.dataLayer || [];
                function gtag() {
                  dataLayer.push(arguments);
                }
                gtag('js', new Date());

                gtag('config', 'UA-172766018-1');`}
              </script>
              <script>{`(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
                new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
                j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
                'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
                })(window,document,'script','dataLayer','GTM-N6BHBDQ');`}</script>
                <script type="text/javascript">
                {`(function () {
                  var css = document.createElement('link');
                  css.href =
                    'https://fonts.googleapis.com/css?family=Lato:400,700,400italic,700italic';
                  css.rel = 'stylesheet';
                  css.type = 'text/css';
                  document.getElementsByTagName('head')[0].appendChild(css);
                })();`}
              </script>
              <script type="text/javascript">
                {`(function () {
                  var css = document.createElement('link');
                  css.href =
                    'https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css';
                  css.rel = 'stylesheet';
                  css.type = 'text/css';
                  document.getElementsByTagName('head')[0].appendChild(css);
                })();`}
              </script>
              <link
                rel="shortcut icon"
                type="image/x-icon"
                href="https://soic.in/images/soic-fav.ico"
              />
              <script type="text/javascript">
                {`(function () {
                  var css = document.createElement('script');
                  css.src = 'https://use.fontawesome.com/releases/v5.13.0/js/all.js';
                  document.getElementsByTagName('head')[0].appendChild(css);
                })();`}
              </script>
              <script>
                {`(function (h, o, t, j, a, r) {
                  h.hj =
                    h.hj ||
                    function () {
                      (h.hj.q = h.hj.q || []).push(arguments);
                    };
                  h._hjSettings = { hjid: 2034328, hjsv: 6 };
                  a = o.getElementsByTagName('head')[0];
                  r = o.createElement('script');
                  r.async = 1;
                  r.src = t + h._hjSettings.hjid + j + h._hjSettings.hjsv;
                  a.appendChild(r);
                })(window, document, 'https://static.hotjar.com/c/hotjar-', '.js?sv=');`}
              </script>
            </Helmet>
            <LazyRoutes />
          </div>
        </Fragment>
      </Router>}
    </Provider>
  );
};

export default App;
