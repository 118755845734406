import {
  GET_BOOK,
  GET_BOOK_ERROR,
  UPDATE_BOOKS,
  UPDATE_BOOKS_ERROR,
  DELETE_BOOKS,
  DELETE_BOOKS_ERROR,
  GET_BOOKS_LIST,
  GET_BOOKS_LIST_ERROR,
  UPLOAD_IMAGE_START,
  UPLOAD_IMAGE_FINISH,
  UPDATE_BOOKS_START,
  RESET_BOOKS_LOADING,
} from '../actions/types';

const initialState = {
  book: null,
  books: [],
  loading: true,
  error: {},
  uploading: false,
  uploadSuccess: false,
  createLoading: false,
};

export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case GET_BOOKS_LIST:
      return {
        ...state,
        books: payload,
        loading: false,
        uploading: false,
      };
    case GET_BOOK:
      return {
        ...state,
        book: payload,
        loading: false,
        uploading: false,
      };
    case UPDATE_BOOKS_START:
      return {
        ...state,
        createLoading: true,
      };
    case UPDATE_BOOKS:
      return {
        ...state,
        book: payload,
        loading: false,
        uploading: false,
        createLoading: false,
      };
    case DELETE_BOOKS:
      return {
        ...state,
        books: state.books.filter((b) => b._id !== payload),
        loading: false,
        uploading: false,
        createLoading: false,
      };
    case GET_BOOK_ERROR:
    case GET_BOOKS_LIST_ERROR:
    case DELETE_BOOKS_ERROR:
    case UPDATE_BOOKS_ERROR:
      return {
        ...state,
        error: payload,
        loading: false,
        book: null,
        books: [],
        uploading: false,
      };
    case UPLOAD_IMAGE_START:
      return {
        ...state,
        uploading: true,
        uploadSuccess: false,
      };
    case UPLOAD_IMAGE_FINISH:
      return {
        ...state,
        uploading: false,
        uploadSuccess: true,
      };
    case RESET_BOOKS_LOADING:
      return {
        ...state,
        createLoading: false,
      };
    default:
      return state;
  }
}
