import {
  GET_VISUALIZATION_LIST,
  GET_VISUALIZATION_LIST_ERROR,
  GET_VISUALIZATION,
  GET_VISUALIZATION_ERROR,
  UPDATE_VISUALIZATION,
  UPDATE_VISUALIZATION_ERROR,
  DELETE_VISUALIZATION,
  DELETE_VISUALIZATION_ERROR,
  UPLOAD_IMAGE_START,
  UPLOAD_IMAGE_FINISH,
} from '../actions/types';

const initialState = {
  visualization: null,
  visualizations: [],
  loading: true,
  error: {},
  uploading: false,
  uploadSuccess: false,
  createLoading: false,
};

export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case GET_VISUALIZATION_LIST:
      return {
        ...state,
        visualizations: payload,
        loading: false,
        uploading: false,
      };
    case GET_VISUALIZATION:
    case UPDATE_VISUALIZATION:
      return {
        ...state,
        visualization: payload,
        loading: false,
        uploading: false,
        createLoading:true
      };
    case DELETE_VISUALIZATION:
      return {
        ...state,
        visualizations: state.visualizations.filter((b) => b._id !== payload),
        loading: false,
        uploading: false,
      };
    case GET_VISUALIZATION_ERROR:
    case GET_VISUALIZATION_LIST_ERROR:
    case UPDATE_VISUALIZATION_ERROR:
    case DELETE_VISUALIZATION_ERROR:
      return {
        ...state,
        error: payload,
        loading: false,
        visualization: null,
        visualizations: [],
        uploading: false,
      };
    case UPLOAD_IMAGE_START:
      return {
        ...state,
        uploading: true,
        uploadSuccess: false,
      };
    case UPLOAD_IMAGE_FINISH:
      return {
        ...state,
        uploading: false,
        uploadSuccess: true,
      };
    default:
      return state;
  }
}
