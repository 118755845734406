import {
  ADD_PODCAST_START,
  ADD_PODCAST_SUCCESS,
  ADD_PODCAST_ERROR,
  FETCH_PODCASTS_START,
  FETCH_PODCASTS_SUCCESS,
  FETCH_PODCASTS_ERROR,
  FETCH_PODCAST_SUCCESS,
  FETCH_PODCAST_ERROR,
  UPDATE_PODCAST_START,
  UPDATE_PODCAST_SUCCESS,
  UPDATE_PODCAST_ERROR,
  DELETE_PODCAST,
} from '../actions/types';

const initialState = {
  podcasts: [],
  podcast: null,
  error: {},
  loading: false,
  createLoading: false,
};

export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case ADD_PODCAST_START:
      return {
        ...state,
        error: {},
        createLoading: true,
      };
    case ADD_PODCAST_SUCCESS:
      return {
        ...state,
        error: {},
      };
    case ADD_PODCAST_ERROR:
      return {
        ...state,
        error: payload,
        createLoading: false,
      };
    case FETCH_PODCASTS_START:
      return {
        ...state,
        loading: true,
        error: {},
      };
    case FETCH_PODCASTS_SUCCESS:
      return {
        ...state,
        error: {},
        loading: false,
        podcasts: payload,
      };
    case FETCH_PODCASTS_ERROR:
      return {
        ...state,
        error: payload,
        loading: false,
        podcasts: [],
      };
    case FETCH_PODCAST_SUCCESS:
      return {
        ...state,
        podcast: payload,
        error: {},
      };
    case FETCH_PODCAST_ERROR:
      return {
        ...state,
        podcast: null,
        error: payload,
      };
    case UPDATE_PODCAST_START:
      return {
        ...state,
        createLoading: true,
        error: {},
      };
    case UPDATE_PODCAST_SUCCESS:
      return {
        ...state,
        createLoading: false,
        error: {},
      };
    case UPDATE_PODCAST_ERROR:
      return {
        ...state,
        createLoading: false,
        error: payload,
      };
    case DELETE_PODCAST:
      return {
        ...state,
        loading: false,
        podcasts: state.podcasts.filter(p => p._id !== payload),
      };
    default:
      return state;
  }
}
